import React from 'react';
import "./privacy.css";

const index = () => {
    return (
        <div className='privacy'>
            <div
                id="wrap-bdy"
                className="body-wrapper"
                onclick="closeMenu(),closeToolTip()"
            >
                <div id="blurdiv" onclick="displayNav('responsive-sidebar')" />
                <section
                    style={{ display: "none" }}
                    className="privacy-content-div back-page-btn"
                >
                    <a href="./account-page.html">Back</a>
                </section>
                <section className="privacy-content-div">
                    <h1>PRIVACY NOTICE</h1>
                    <h5 style={{ fontSize: 14, marginTop: "-5px", marginBottom: 15 }}>
                        (Last updated July 06, 2020)
                    </h5>
                    Thank you for choosing to be part of our community at EshippingQuote Co. ("
                    <strong>Company</strong>", "<strong>we</strong>", "<strong>us</strong>",
                    or "<strong>our</strong>"). We are committed to protecting your personal
                    information and your right to privacy. If you have any questions or
                    concerns about this privacy notice, or our practices with regards to your
                    personal information, please contact us at EshippingQuote@gmail.com.
                    <br />
                    <br /> When you visit our website{" "}
                    <a style={{ color: "#0266ff" }} href="https://eshippingquote.com">
                        https://eshippingquote.com
                    </a>{" "}
                    (the "<strong>Website</strong>"), and more generally, use any of our
                    services (the "Services", which include the Website), we appreciate that
                    you are trusting us with your personal information. We take your privacy
                    very seriously. In this privacy notice, we seek to explain to you in the
                    clearest way possible what information we collect, how we use it and what
                    rights you have in relation to it. We hope you take some time to read
                    through it carefully, as it is important. If there are any terms in this
                    privacy notice that you do not agree with, please discontinue use of our
                    Services immediately.
                    <br />
                    <br /> This privacy notice applies to all information collected through
                    our Services (which, as described above, includes our Website), as well as
                    any related services, sales, marketing or events.
                    <br />
                    <br />{" "}
                    <b>
                        Please read this privacy notice carefully as it will help you understand
                        what we do with the information that we collect.
                    </b>
                </section>
                <section className="privacy-content-div" id="table-content">
                    <h1>TABLE OF CONTENTS</h1>
                    <br /> <br />
                    <a href="#t1">1. WHAT INFORMATION DO WE COLLECT?</a>
                    <br /> <br />
                    <a href="#t2">2. HOW DO WE USE YOUR INFORMATION?</a>
                    <br /> <br />
                    <a href="#t3">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a> <br />
                    <br />
                    <a href="#t4">4. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
                    <br />
                    <br />
                    <a href="#t5">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
                    <br /> <br /> <a href="#t6"> 6. DO WE COLLECT INFORMATION FROM MINORS?</a>
                    <br />
                    <br /> <a href="#t7">7. WHAT ARE YOUR PRIVACY RIGHTS?</a>
                    <br />
                    <br />
                    <a href="#t8"> 8. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
                    <br />
                    <br />{" "}
                    <a href="#t9">9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
                    <br /> <br />
                    <a href="#t10">10. DO WE MAKE UPDATES TO THIS NOTICE?</a>
                    <br /> <br />
                    <a href="#t11">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                    <br />
                    <br />
                </section>
                <section className="privacy-content-div" id="t1">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        1. WHAT INFORMATION DO WE COLLECT?
                    </h1>
                    <h3>Personal information you disclose to us</h3>
                    <div>
                        <b>In Short:</b> <i>We collect information that you provide to us.</i>
                        <br />
                        <br /> We collect personal information that you voluntarily provide to
                        us when you register on the Website, express an interest in obtaining
                        information about us or our products and Services, when you participate
                        in activities on the Website or otherwise when you contact us. <br />
                        <br /> The personal information that we collect depends on the context
                        of your interactions with us and the Website, the choices you make and
                        the products and features you use. The personal information we collect
                        may include the following:
                        <br />
                        <br /> <b> Personal Information Provided by You.</b> We collect names;
                        phone numbers; email addresses; mailing addresses; usernames; passwords;
                        billing addresses; and other similar information.
                        <br />
                        <br /> <b>Payment Data.</b> We may collect data necessary to process
                        your payment if you make purchases, such as your payment instrument
                        number (such as a credit card number), and the security code associated
                        with your payment instrument. All payment data is stored by PayPal.
                        <br />
                        <br /> All personal information that you provide to us must be true,
                        complete and accurate, and you must notify us of any changes to such
                        personal information.
                    </div>
                </section>
                <section className="privacy-content-div" id="t2">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        2. HOW DO WE USE YOUR INFORMATION?
                    </h1>
                    <div>
                        <b>In Short:</b>
                        <i>
                            {" "}
                            We process your information for purposes based on legitimate business
                            interests, the fulfillment of our contract with you, compliance with
                            our legal obligations, and/or your consent.
                        </i>
                        <br />
                        <br /> We use personal information collected via our Website for a
                        variety of business purposes described below. We process your personal
                        information for these purposes in reliance on our legitimate business
                        interests, in order to enter into or perform a contract with you, with
                        your consent, and/or for compliance with our legal obligations. We
                        indicate the specific processing grounds we rely on next to each purpose
                        listed below.
                        <br />
                        <br /> We use the information we collect or receive:
                        <br />
                        <br />
                        <ul>
                            <li>
                                <b>To facilitate account creation and logon process.</b> If you
                                choose to link your account with us to a third-party account (such
                                as your Google or Facebook account), we use the information you
                                allowed us to collect from those third parties to facilitate account
                                creation and logon process for the performance of the contract.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>To post testimonials.</b> We post testimonials on our Website
                                that may contain personal information. Prior to posting a
                                testimonial, we will obtain your consent to use your name and the
                                consent of the testimonial. If you wish to update, or delete your
                                testimonial, please contact us at EshippingQuote@gmail.com and be sure
                                to include your name, testimonial location, and contact information.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>Request feedback.</b> We may use your information to request
                                feedback and to contact you about your use of our Website.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>To enable user-to-user communications.</b> We may use your
                                information in order to enable user-to-user communications with each
                                user's consent.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>To manage user accounts.</b> We may use your information for the
                                purposes of managing our account and keeping it in working order.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>To send administrative information to you.</b> We may use your
                                personal information to send you product, service and new feature
                                information and/or information about changes to our terms,
                                conditions, and policies.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>To protect our Services.</b> We may use your information as part
                                of our efforts to keep our Website safe and secure (for example, for
                                fraud monitoring and prevention).
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>
                                    To enforce our terms, conditions and policies for business
                                    purposes, to comply with legal and regulatory requirements or in
                                    connection with our contract.
                                </b>
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>Fulfill and manage your orders.</b> We may use your information
                                to fulfill and manage your orders, payments, returns, and exchanges
                                made through the Website.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>Administer prize draws and competitions.</b>We may use your
                                information to administer prize draws and competitions when you
                                elect to participate in our competitions.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>To deliver and facilitate delivery of services to the user. </b>
                                We may use your information to provide you with the requested
                                service.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>To respond to user inquiries/offer support to users. </b>We may
                                use your information to respond to your inquiries and solve any
                                potential issues you might have with the use of our Services.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>To send you marketing and promotional communications.</b>We
                                and/or our third-party marketing partners may use the personal
                                information you send to us for our marketing purposes, if this is in
                                accordance with your marketing preferences. For example, when
                                expressing an interest in obtaining information about us or our
                                Website, subscribing to marketing or otherwise contacting us, we
                                will collect personal information from you. You can opt-out of our
                                marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS"
                                below).
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>Deliver targeted advertising to you.</b>We may use your
                                information to develop and display personalized content and
                                advertising (and work with third parties who do so) tailored to your
                                interests and/or location and to measure its effectiveness.
                            </li>
                            <br />
                            <br />
                            <li>
                                <b>For other business purposes.</b> We may use your information for
                                other business purposes, such as data analysis, identifying usage
                                trends, determining the effectiveness of our promotional campaigns
                                and to evaluate and improve our Website, products, marketing and
                                your experience. We may use and store this information in aggregated
                                and anonymized form so that it is not associated with individual end
                                users and does not include personal information. We will not use
                                identifiable personal information without your consent.
                            </li>
                            <br />
                            <br />
                        </ul>
                    </div>
                </section>
                <section className="privacy-content-div" id="t3">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </h1>
                    <div>
                        <b>In Short:</b>{" "}
                        <i>
                            We only share information with your consent, to comply with laws, to
                            provide you with services, to protect your rights, or to fulfill
                            business obligations.
                        </i>
                        <br />
                        <br />
                        We may process or share your data that we hold based on the following
                        legal basis:
                        <br />
                        <br />
                        <ul>
                            <li>
                                <b>Consent:</b>We may process your data if you have given us
                                specific consent to use your personal information in a specific
                                purpose.
                                <br />
                                <br />
                            </li>
                            <li>
                                <b>Legitimate Interests:</b>We may process your data when it is
                                reasonably necessary to achieve our legitimate business interests.
                                <br />
                                <br />
                            </li>
                            <li>
                                <b>Performance of a Contract:</b>Where we have entered into a
                                contract with you, we may process your personal information to
                                fulfill the terms of our contract.
                                <br />
                                <br />
                            </li>
                            <li>
                                <b>Legal Obligations:</b> We may disclose your information where we
                                are legally required to do so in order to comply with applicable
                                law, governmental requests, a judicial proceeding, court order, or
                                legal process, such as in response to a court order or a subpoena
                                (including in response to public authorities to meet national
                                security or law enforcement requirements).
                                <br />
                                <br />
                            </li>
                            <li>
                                <b>Vital Interests:</b>We may disclose your information where we
                                believe it is necessary to investigate, prevent, or take action
                                regarding potential violations of our policies, suspected fraud,
                                situations involving potential threats to the safety of any person
                                and illegal activities, or as evidence in litigation in which we are
                                involved.
                                <br />
                                <br />
                            </li>
                            <b>
                                More specifically, we may need to process your data or share your
                                personal information in the following situations:
                            </b>
                            <br />
                            <br />
                            <li>
                                <b>Business Transfers:</b>We may share or transfer your information
                                in connection with, or during negotiations of, any merger, sale of
                                company assets, financing, or acquisition of all or a portion of our
                                business to another company.
                                <br />
                                <br />
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="privacy-content-div" id="t4">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        4. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </h1>
                    <div>
                        <b>In Short:</b>{" "}
                        <i>
                            We keep your information for as long as necessary to fulfill the
                            purposes outlined in this privacy notice unless otherwise required by
                            law.
                        </i>
                        <br />
                        <br /> We will only keep your personal information for as long as it is
                        necessary for the purposes set out in this privacy notice, unless a
                        longer retention period is required or permitted by law (such as tax,
                        accounting or other legal requirements). No purpose in this notice will
                        require us keeping your personal information for longer than three (3)
                        months past the termination of the user's account.
                        <br />
                        <br /> When we have no ongoing legitimate business need to process your
                        personal information, we will either delete or anonymize such
                        information, or, if this is not possible (for example, because your
                        personal information has been stored in backup archives), then we will
                        securely store your personal information and isolate it from any further
                        processing until deletion is possible.
                        <br />
                        <br />
                    </div>
                </section>
                <section className="privacy-content-div" id="t5">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        5. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </h1>
                    <div>
                        <b>In Short:</b>{" "}
                        <i>
                            We aim to protect your personal information through a system of
                            organizational and technical security measures.
                        </i>
                        <br />
                        <br /> We have implemented appropriate technical and organizational
                        security measures designed to protect the security of any personal
                        information we process. However, despite our safeguards and efforts to
                        secure your information, no electronic transmission over the Internet or
                        information storage technology can be guaranteed to be 100% secure, so
                        we cannot promise or guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat our security, and
                        improperly collect, access, steal, or modify your information. Although
                        we will do our best to protect your personal information, transmission
                        of personal information to and from our Website is at your own risk. You
                        should only access the Website within a secure environment.
                        <br />
                        <br />
                    </div>
                </section>
                <section className="privacy-content-div" id="t6">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        6. DO WE COLLECT INFORMATION FROM MINORS?
                    </h1>
                    <div>
                        <b>In Short:</b>{" "}
                        <i>
                            We do not knowingly collect data from or market to children under 18
                            years of age.
                        </i>
                        <br />
                        <br /> We do not knowingly solicit data from or market to children under
                        18 years of age. By using the Website, you represent that you are at
                        least 18 or that you are the parent or guardian of such a minor and
                        consent to such minor dependent’s use of the Website. If we learn that
                        personal information from users less than 18 years of age has been
                        collected, we will deactivate the account and take reasonable measures
                        to promptly delete such data from our records. If you become aware of
                        any data we may have collected from children under age 18, please
                        contact us at EshippingQuote@gmail.com.
                        <br />
                        <br />
                    </div>
                </section>
                <section className="privacy-content-div" id="t7">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        7. WHAT ARE YOUR PRIVACY RIGHTS?{" "}
                    </h1>
                    <div>
                        <b>In Short:</b>{" "}
                        <i>You may review, change, or terminate your account at any time.</i>
                        <br />
                        <br /> If you are resident in the European Economic Area and you believe
                        we are unlawfully processing your personal information, you also have
                        the right to complain to your local data protection supervisory
                        authority. You can find their contact details here:{" "}
                        <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html">
                            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html
                        </a>
                        <br />
                        <br /> If you are resident in Switzerland, the contact details for the
                        data protection authorities are available here:
                        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                            https://www.edoeb.admin.ch/edoeb/en/home.html
                        </a>
                        <br />
                        <br />
                        <b>Account Information</b>
                        <br />
                        <br /> If you would at any time like to review or change the information
                        in your account or terminate your account, you can:
                        <br />
                        <br /> ■ Log in to your account settings and update your user account.
                        <br />
                        <br /> Upon your request to terminate your account, we will deactivate
                        or delete your account and information from our active databases.
                        However, we may retain some information in our files to prevent fraud,
                        troubleshoot problems, assist with any investigations, enforce our Terms
                        of Use and/or comply with applicable legal requirements.
                        <br />
                        <br />{" "}
                        <b>
                            <u>Opting out of email marketing:</u>
                        </b>{" "}
                        You can unsubscribe from our marketing email list at any time by
                        clicking on the unsubscribe link in the emails that we send or by
                        contacting us using the details provided below. You will then be removed
                        from the marketing email list – however, we may still communicate with
                        you, for example to send you service-related emails that are necessary
                        for the administration and use of your account, to respond to service
                        requests, or for other non-marketing purposes. To otherwise opt-out, you
                        may:
                        <br />
                        <br /> ■ Contact us using the contact information provided.
                    </div>
                </section>
                <section className="privacy-content-div" id="t8">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        8. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </h1>
                    <div>
                        Most web browsers and some mobile operating systems and mobile
                        applications include a Do-Not-Track (“DNT”) feature or setting you can
                        activate to signal your privacy preference not to have data about your
                        online browsing activities monitored and collected. At this stage, no
                        uniform technology standard for recognizing and implementing DNT signals
                        has been finalized. As such, we do not currently respond to DNT browser
                        signals or any other mechanism that automatically communicates your
                        choice not to be tracked online. If a standard for online tracking is
                        adopted that we must follow in the future, we will inform you about that
                        practice in a revised version of this privacy notice.
                    </div>
                </section>
                <section className="privacy-content-div" id="t9">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </h1>
                    <div>
                        <b>In Short:</b>{" "}
                        <i>
                            Yes, if you are a resident of California, you are granted specific
                            rights regarding access to your personal information.
                        </i>
                        <br />
                        <br /> California Civil Code Section 1798.83, also known as the “Shine
                        The Light” law, permits our users who are California residents to
                        request and obtain from us, once a year and free of charge, information
                        about categories of personal information (if any) we disclosed to third
                        parties for direct marketing purposes and the names and addresses of all
                        third parties with which we shared personal information in the
                        immediately preceding calendar year. If you are a California resident
                        and would like to make such a request, please submit your request in
                        writing to us using the contact information provided below.
                        <br />
                        <br /> If you are under 18 years of age, reside in California, and have
                        a registered account with the Website, you have the right to request
                        removal of unwanted data that you publicly post on the Website. To
                        request removal of such data, please contact us using the contact
                        information provided below, and include the email address associated
                        with your account and a statement that you reside in California. We will
                        make sure the data is not publicly displayed on the Website, but please
                        be aware that the data may not be completely or comprehensively removed
                        from all our systems (e.g. backups, etc.).
                        <br />
                        <br /> <b>CCPA Privacy Notice</b>
                        <br />
                        <br /> The California Code of Regulations defines a "resident" as: (1)
                        every individual who is in the State of California for other than a
                        temporary or transitory purpose and (2) every individual who is
                        domiciled in the State of California who is outside the State of
                        California for a temporary or transitory purpose All other individuals
                        are defined as "non-residents." If this definition of "resident" applies
                        to you, certain rights and obligations apply regarding your personal
                        information.
                        <br />
                        <br /> <b>What categories of personal information do we collect?</b> We
                        have collected the following categories of personal information in the
                        past twelve (12) months:
                        <br />
                        <br />
                        <div className="table">
                            <div className="tr">
                                <div className="td td-cat">Category</div>
                                <div className="td td-ex">Examples</div>
                                <div className="td td-coll">Collected</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat">Identifiers </div>
                                <div className="td td-ex">
                                    Contact details, such as real name, alias, postal address,
                                    telephone or mobile contact number, unique personal identifier,
                                    online identifier, Internet Protocol address, email address and
                                    account name
                                </div>
                                <div className="td td-coll">NO</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat">
                                    {" "}
                                    Personal information categories listed in the California Customer
                                    Records statute
                                </div>
                                <div className="td td-ex">
                                    Name, contact information, education, employment, employment
                                    history and financial information
                                </div>
                                <div className="td td-coll">YES</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat">
                                    {" "}
                                    Protected classification characteristics under California or
                                    federal law
                                </div>
                                <div className="td td-ex">Gender and date of birth</div>
                                <div className="td td-coll">NO</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat"> Commercial information</div>
                                <div className="td td-ex">
                                    Transaction information, purchase history, financial details and
                                    payment information
                                </div>
                                <div className="td td-coll">NO</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat">Biometric information</div>
                                <div className="td td-ex">Fingerprints and voiceprints</div>
                                <div className="td td-coll">NO</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat">
                                    Internet or other similar network activity
                                </div>
                                <div className="td td-ex">
                                    Browsing history, search history, online behavior, interest data,
                                    and interactions with our and other websites, applications,
                                    systems and advertisements
                                </div>
                                <div className="td td-coll">NO</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat"> Geolocation data</div>
                                <div className="td td-ex">Device location</div>
                                <div className="td td-coll">NO</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat">
                                    Audio, electronic, visual, thermal, olfactory, or similar
                                    information
                                </div>
                                <div className="td td-ex">
                                    Images and audio, video or call recordings created in connection
                                    with our business activities
                                </div>
                                <div className="td td-coll">NO</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat">
                                    Professional or employment-related information
                                </div>
                                <div className="td td-ex">
                                    Business contact details in order to provide you our services at a
                                    business level, job title as well as work history and professional
                                    qualifications if you apply for a job with us
                                </div>
                                <div className="td td-coll">NO</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat"> Education Information</div>
                                <div className="td td-ex">
                                    Student records and directory information
                                </div>
                                <div className="td td-coll">NO</div>
                            </div>
                            <div className="tr">
                                <div className="td td-cat">
                                    {" "}
                                    Inferences drawn from other personal information
                                </div>
                                <div className="td td-ex">
                                    Inferences drawn from any of the collected personal information
                                    listed above to create a profile or summary about, for example, an
                                    individual’s preferences and characteristics
                                </div>
                                <div className="td td-coll">NO</div>
                            </div>
                        </div>
                        We may also collect other personal information outside of these
                        categories in instances where you interact with us in-person, online, or
                        by phone or mail in the context of:
                        <ul>
                            <li>Receiving help through our customer support channels</li>
                            <li>Participation in customer surveys or contests; and</li>
                            <li>
                                Facilitation in the delivery of our Services and to respond to your
                                inquiries
                            </li>
                        </ul>
                        <br />
                        <br />
                        <b>How do we use and share your personal information?</b>
                        <br />
                        <br /> More information about our data collection and sharing practices
                        can be found in this privacy notice. You may contact us by email at
                        EshippingQuote@gmail.com, or by referring to the contact details at the
                        bottom of this document. If you are using an authorized agent to
                        exercise your right to opt-out, we may deny a request if the authorized
                        agent does not submit proof that they have been validly authorized to
                        act on your behalf. <br />
                        <br />
                        <b> Will your information be shared with anyone else?</b>
                        <br />
                        <br />
                        We may disclose your personal information with our service providers
                        pursuant to a written contract between us and each service provider.
                        Each service provider is a for-profit entity that processes the
                        information on our behalf.
                        <br />
                        <br /> We may use your personal information for our own business
                        purposes, such as for undertaking internal research for technological
                        development and demonstration. This is not considered to be "selling" of
                        your personal data.
                        <br />
                        <br /> EshippingQuote Co. has not disclosed or sold any personal
                        information to third parties for a business or commercial purpose in the
                        preceding twelve (12) months. EshippingQuote Co. will not sell personal
                        information in the future belonging to website visitors, users and other
                        consumers.
                        <br />
                        <br />
                        <b>Your rights with respect to your personal data</b>
                        <br />
                        <br />
                        <u>Right to request deletion of the data - Request to delete</u>
                        <br />
                        <br />
                        You can ask for the deletion of your personal information. If you ask us
                        to delete your personal information, we will respect your request and
                        delete your personal information, subject to certain exceptions provided
                        by law, such as (but not limited to) the exercise by another consumer of
                        his or her right to free speech, our compliance requirements resulting
                        from a legal obligation or any processing that may be required to
                        protect against illegal activities.
                        <br />
                        <br />
                        <u>Right to be informed - Request to know</u>
                        <br />
                        <br /> Depending on the circumstances, you have a right to know:
                        <br />
                        <br />
                        <ul>
                            <li>whether we collect and use your personal information;</li>
                            <li>the categories of personal information that we collect;</li>
                            <li>
                                the purposes for which the collected personal information is used;
                            </li>
                            <li>whether we sell your personal information to third parties;</li>
                            <li>
                                the categories of personal information that we sold or disclosed for
                                a business purpose;
                            </li>
                            <li>
                                the categories of third parties to whom the personal information was
                                sold or disclosed for a business purpose; and
                            </li>
                            <li>
                                the business or commercial purpose for collecting or selling
                                personal information.
                            </li>
                        </ul>
                        In accordance with applicable law, we are not obligated to provide or
                        delete consumer information that is de-identified in response to a
                        consumer request or to re-identify individual data to verify a consumer
                        request.
                        <br />
                        <br />
                        <u>
                            Right to Non-Discrimination for the Exercise of a Consumer's Privacy
                            Rights
                        </u>
                        <br />
                        <br /> We will not discriminate against you if you exercise your privacy
                        rights.
                        <br />
                        <br />
                        <u>Verification Process</u>
                        <br />
                        <br /> Upon receiving your request, we will need to verify your identity
                        to determine you are the same person about whom we have the information
                        in our system. These verification efforts require us to ask you to
                        provide information so that we can match it with the information you
                        have previously provided us. For instance, depending on the type of
                        request you submit, we may ask you to provide certain information so
                        that we can match the information you provide with the information we
                        already have on file, or we may contact you through a communication
                        method (e.g. phone or email) that you have previously provided to us. We
                        may also use other verification methods as the circumstances dictate.
                        <br />
                        <br /> We will only use personal information provided in your request to
                        verify your identity or authority to make the request. To the extent
                        possible, we will avoid requesting additional information from you for
                        the purposes of verification. If, however, if we cannot verify your
                        identity from the information already maintained by us, we may request
                        that you provide additional information for the purposes of verifying
                        your identity, and for security or fraud-prevention purposes. We will
                        delete such additionally provided information as soon as we finish
                        verifying you.
                        <br />
                        <br />
                        <u>Other privacy rights</u>
                        <br />
                        <br />
                        <ul>
                            <li>you may object to the processing of your personal data</li>
                            <li>
                                you may request correction of your personal data if it is incorrect
                                or no longer relevant, or ask to restrict the processing of the data
                            </li>
                            <li>
                                you can designate an authorized agent to make a request under the
                                CCPA on your behalf. We may deny a request from an authorized agent
                                that does not submit proof that they have been validly authorized to
                                act on your behalf in accordance with the CCPA.
                            </li>
                            <li>
                                you may request to opt-out from future selling of your personal
                                information to third parties. Upon receiving a request to opt-out,
                                we will act upon the request as soon as feasibly possible, but no
                                later than 15 days from the date of the request submission.
                            </li>
                        </ul>
                        <br />
                        <br /> To exercise these rights, you can contact us by email at
                        EshippingQuote@gmail.com, or by referring to the contact details at the
                        bottom of this document. If you have a complaint about how we handle
                        your data, we would like to hear from you.
                    </div>
                </section>
                <section className="privacy-content-div" id="t10">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        10. DO WE MAKE UPDATES TO THIS NOTICE?
                    </h1>
                    <div>
                        <b>In Short:</b>{" "}
                        <i>
                            {" "}
                            Yes, we will update this notice as necessary to stay compliant with
                            relevant laws.
                        </i>
                        <br />
                        <br /> We may update this privacy notice from time to time. The updated
                        version will be indicated by an updated “Revised” date and the updated
                        version will be effective as soon as it is accessible. If we make
                        material changes to this privacy notice, we may notify you either by
                        prominently posting a notice of such changes or by directly sending you
                        a notification. We encourage you to review this privacy notice
                        frequently to be informed of how we are protecting your information.
                        <br />
                        <br />
                    </div>
                </section>
                <section className="privacy-content-div" id="t11">
                    <h1 style={{ textAlign: "left", paddingBottom: 10 }}>
                        11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </h1>
                    <div>
                        If you have questions or comments about this notice, you may email us at
                        EshippingQuote@gmail.com or by post to:
                        <br />
                        <br /> EshippingQuote Co. {/*<br /> 2118 CARDWELL RD <br /> CROZIER, VA
                        23039-2109*/} <br /> United States <br />
                        <br />
                        <br />
                        <br />
                        <b>
                            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                        </b>
                        <br />
                        <br /> Based on the applicable laws of your country, you may have the
                        right to request access to the personal information we collect from you,
                        change that information, or delete it in some circumstances. To request
                        to review, update, or delete your personal information, please send your
                        request to EshippingQuote@gmail.com. We will respond to your request within
                        30 days.
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </section>
            </div>
        </div>

    )
}

export default index