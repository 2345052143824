import React from "react";
import "./modal.css";
import CancelIcon from "../../assets/icons/cancel.svg";
import { useNavigate } from "react-router-dom";

const SignInSignUp = (props) => {
    const navigate = useNavigate();
    const redirectTo = (whereto) => {
        window.open(whereto, "_blank"); // Replace "/signin" with your actual sign-in route
    };

    return (
        <div className="modal-content add-account-modal">
            <span className="close" onClick={props.closeModal}>
                <img
                    style={{ height: "20px", width: "20px" }}
                    src={CancelIcon}
                    alt="ci"
                />
            </span>
            <h4>Please Sign In/Register to select this shipment. </h4>
            <p>Please Sign In to select this shipment. If you have not Signed Up or completed your registration, these are required before booking this shipment.”</p>
            <div className="btn-tray">
                <button className="primary-theme-btn" onClick={() => redirectTo("/login")}>
                    Sign in
                </button>
                <button
                    className="secondary-theme-btn"
                    style={{ color: "black" }}
                    onClick={() => redirectTo("/register")}
                >
                    Register
                </button>
            </div>
        </div>
    );
};

export default SignInSignUp;
