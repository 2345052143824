import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ButtonCell from "./Cells/ButtonCell";
import ColorBoxCell from "./Cells/ColorBoxCell";
import LinkCell from "./Cells/LinkCell";
import RoundTextCell from "./Cells/RoundTextCell";
import TextBoldCell from "./Cells/TextBoldCell";
import TextCell from "./Cells/TextCell";
import TransitPathCell from "./Cells/TransitPathCell";
import CostCell from "./Cells/CostCell";
import { useNavigate } from "react-router-dom";
import arrowhead from "../../assets/icons/arrowhead.svg";
import "./table.css";
import ColorBoxCellMobile from "./Cells/ColorBoxCellMobile";



const PaginationTable = ({ tableConfig, extraClasses, hideHighLightRow, msg,pageType }) => {
  const platformView = useSelector(
    (state) => state.platformView.renderViewType
  );
  const { data, headers, colWidth, config } = tableConfig;
  const navigate = useNavigate();
  const headerKeysOrdered = headers.map(({ key }) => key);
  const [isRenderingTable, setIsRenderingTable] = useState(false);
  const [tableData, setTableData] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [sortOption, setSortOption] = useState("costLowToHigh");
  const tableTopRef = useRef(null);

  const pageSize = 100;
  const paginationCount = data && data[0] ? Math.min(Math.ceil(data[0].length / pageSize)/*, 5*/) : 0;
  const showPaginationControls = paginationCount > 1; // Only show pagination controls when there are more than 1 page
  const isOnFirstPage = pageIndex === 0;
  const isOnLastPage = pageIndex === paginationCount - 1;
  const showSortingControls = pageType === "quoteresults";
  //Siddharth code
  /*const nextIndex = () => {
    if (pageIndex === paginationCount - 1) return;
    setPageIndex(pageIndex + 1);
  };

  const prevIndex = () => {
    if(pageIndex === 0) return;
    setPageIndex(pageIndex - 1);
  };
  */

  const nextIndex = () => {
    if (!isOnLastPage) {
      setIsRenderingTable(true);
      setPageIndex(pageIndex + 1);
    }
  };

  const prevIndex = () => {
    if (!isOnFirstPage) {
      setIsRenderingTable(true);
      setPageIndex(pageIndex - 1);
    }
  };

  const sortingOptions = [
    { label: "Price (Low to High)", value: "costLowToHigh" },
    { label: "Price (High to Low)", value: "costHighToLow" },
    { label: "Shortest Transit Time", value: "transitTime" },
    { label: "Reliability", value: "reliability" },
    { label: "Earliest Delivery Date", value: "deliveryDate" },
  ];

  const sortData = (option) => {
    console.log("sorting begins")

    // Your sorting logic goes here
    // You might want to sort the data based on the selected option
    // Update the 'tableData' state accordingly
    // For simplicity, let's assume 'tableData' is an array of objects
    let sortedData = [...tableData];
    console.log(sortedData);
    switch (option) {
      case "costLowToHigh":
        sortedData.sort((a, b) => a.costIntValue - b.costIntValue);
        break;
      case "costHighToLow":
        sortedData.sort((a, b) => b.costIntValue  - a.costIntValue );
        break;
      case "transitTime":
        sortedData.sort((a, b) => a.transitIntValue - b.transitIntValue);
        break;
      case "reliability":
        sortedData.sort((a, b) => b.reliabilityIntValue - a.reliabilityIntValue);
        break;
      case "deliveryDate":
        sortedData.sort((a, b) => new Date(a.deliveryDateValue) - new Date(b.deliveryDateValue));
        break;
      default:
        // Default case for Shortest Transit Time
        sortedData.sort((a, b) => a.transitIntValue - b.transitIntValue);
        break;
    }
    // Update the 'number' property based on the sorted order
    sortedData.forEach((row, index) => {
      row.number.value = index + 1;
    });
    setSortOption(option);
    setTableData(sortedData);
    setPageIndex(0); // Reset pageIndex when sorting to start from the first page
  };

  useEffect(() => {
    const _pageData = tableData && tableData.length ? tableData.slice(pageIndex * pageSize, (pageIndex * pageSize) + pageSize) : [];
    if (!_pageData) {
      //if data is available in server we will mimic an api call over here to fetch the data for that index
      return;
    }
    setPageData(_pageData);
    setIsRenderingTable(false);
  }, [pageIndex, tableData]);

  useEffect(() => {
    const indexes = Object.keys(data);
    if (indexes.length === 0) {
      return;
    }
    if(!showSortingControls){
      setTableData(data[0]);
      setPageData(data[indexes[0]]);
    }else{
      let updatedTableData = data[0];
      let updatedPageData = data[indexes[0]];
      updatedTableData.sort((a, b) => a.costIntValue - b.costIntValue);
      // Update the 'number' property based on the sorted order
      updatedTableData.forEach((row, index) => {
        row.number.value = index + 1;
      });
      setTableData(updatedTableData);
      setPageData(updatedPageData);
    }

  }, [data]);

  return !isRenderingTable ?(
    <div
      className={
        "pagination-table " + (extraClasses ? extraClasses.join(" ") : "")
      }
      ref={tableTopRef}
    >
      {/*<div className="table-search">
        <input placeholder="Search" type="text" />
      </div>*/}
      {showSortingControls && ( <div className="table-search">
        <select onChange={(e) => sortData(e.target.value)} value={sortOption}>
          {sortingOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
          ))}
        </select>
      </div>
      )}
      {platformView === "desktop" && (
        <div className="table-header">
          {headers.map((header, i) => {
            const headerVisible =
              header.visible && header.visible === "no" ? "hidden" : "visible";
            if (header.type === "bold") {
              return (
                <div
                  key={"table_header" + i}
                  style={{ width: colWidth[i], visibility: headerVisible }}
                  className="table-header-cell"
                >
                  <span>{header.name}</span>
                </div>
              );
            }
          })}
        </div>
      )}

      <div className="table-rows">
        {!pageData.length && <center><h2>{msg}</h2></center>}
        {pageData && pageData.map((row, i) => {
          return (
            <div

              className={
                "wrapper-table-row " +
                `${
                  (config &&
                    config?.rowSelectionActive &&
                    window.selectedRow && window.selectedRow.includes(row.routeNumber))
                    ? "open"
                    : ""
                }`
              }
              style={{ display: window.selectedBooking?.includes(row.routeNumber) ? 'none' : 'block'}}
            >
              <div key={"table_row" + i} className={`table-row ${hideHighLightRow ? '' : 'highLightRow'}`} onClick={() => row.transitDetails ? row.transitDetails() : row["shipmentId"] ? navigate(`/shipment-details/${row["shipmentId"].value}`) : null}>
                {headerKeysOrdered.map((key, j) => {
                  const cell = row[key];

                  if (cell.cellType === "round-text") {
                    return (
                      <RoundTextCell
                        key={"table_row" + i + cell + j}
                        width={colWidth[j]}
                        value={cell.value}
                      />
                    );
                  } else if (cell.cellType === "text") {
                    return (
                      <TextCell
                        key={"table_row" + i + cell + j}
                        width={colWidth[j]}
                        value={cell.value}
                        header={headers[j].key}
                      />
                    );
                  } else if (cell.cellType === "bold-text") {
                    return (
                      <TextBoldCell
                        key={"table_row" + i + cell + j}
                        width={colWidth[j]}
                        value={cell.value}
                      />
                    );
                  } else if (cell.cellType === "link-text") {
                    return (
                      <LinkCell
                        key={"table_row" + i + cell + j}
                        width={colWidth[j]}
                        value={cell.value}
                        header={headers[j].key}
                      />
                    );
                  } else if (cell.cellType === "buttons") {
                    return (
                      <ButtonCell
                        key={"table_row" + i + cell + j}
                        width={colWidth[j]}
                        buttons={cell.buttons}
                      />
                    );
                  } else if (cell.cellType === "lineItemCharges") {
                    return (
                      <CostCell
                        key={"table_row" + i + cell + j}
                        width={colWidth[j]}
                        path={cell.value}
                      />
                    );
                  } else if (cell.cellType === "transitPath") {
                    return (
                        <TransitPathCell
                            key={"table_row" + i + cell + j}
                            width={colWidth[j]}
                            path={cell.value}
                        />
                    );
                  } else if (cell.cellType === "colorBox") {
                    if (
                      platformView === "mobile" &&
                      headers[j].key === "reliability"
                    )
                      return (
                        <ColorBoxCellMobile
                          key={"table_row" + i + cell + j}
                          width={colWidth[j]}
                          value={cell.value}
                          color={cell.color}
                          textColor={cell.textColor}
                        />
                      );

                    return (
                      <ColorBoxCell
                        key={"table_row" + i + cell + j}
                        width={colWidth[j]}
                        value={cell.value}
                        color={cell.color}
                        textColor={cell.textColor}
                      />
                    );
                  } else {
                    return (
                      <div
                        className="table-cell"
                        key={"table_row" + i + cell + j}
                        style={{ width: colWidth[j] }}
                      ></div>
                    );
                  }
                })}
              </div>
              {config && config?.rowSelectionActive && (
                <div className="dropdown-cell">
                  <div className="dropdown-cell-content">
                    {window.selectedMetaData && window.selectedMetaData[row.routeNumber] && Object.entries(window.selectedMetaData[row.routeNumber]).map(
                      ([key, val], k) => {
                        return (
                          <div
                            key={key + row.routeNumber + k.toString()}
                            className={`row--${k.toString()} dropdown-row`}
                          >
                            <span className="dropdown-row-left">
                              <strong>
                                {key
                                  .replace(/([A-Z])/g, " $1")
                                  .replace(/^./, function (str) {
                                    return str.toUpperCase();
                                  })}
                                  :
                              </strong>
                              </span>
                              <span className="dropdown-row-right">
                              {val != null ? val : "N/A"}
                            </span>
                            {/* <span>
                    <strong>On Time Pick Up:</strong>90%
                  </span> */}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div
                    onClick={() => config?.removeRowSelection(row.routeNumber)}
                    className="close-dropdown"
                  >
                    <span></span>
                    <img src={arrowhead}></img>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div
        className={
          "table-pagination-controller " +
          (config?.navigationPosition ? config?.navigationPosition : "")
        }
      >
        {/*<button className="primary-theme-btn" onClick={prevIndex}>
          Previous
        </button>
        {Array(paginationCount).fill(-1).map((_, index) => {
          return (
            <button
              className={
                "primary-theme-btn " +
                (index.toString() === pageIndex.toString()
                  ? "selected"
                  : "")
              }
              key={"page_" + index}
              onClick={() => {
                setPageIndex(index);
              }}
            >
              {parseInt(index) + 1}
            </button>
          );
        })}
        <button className="primary-theme-btn" onClick={nextIndex}>
          Next
        </button>*/}
        {showPaginationControls && (
            <>
              <button className="primary-theme-btn" onClick={() => { prevIndex(); tableTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });}} disabled={isOnFirstPage}>
                Previous
              </button>
              {Array(paginationCount).fill(-1).map((_, index) => (
                  <button
                      className={"primary-theme-btn " + (index.toString() === pageIndex.toString() ? "selected" : "")}
                      key={"page_" + index}
                      disabled={index.toString() === pageIndex.toString()}
                      onClick={() => {setIsRenderingTable(true);setPageIndex(index);tableTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });}}
                  >
                    {parseInt(index) + 1}
                  </button>
              ))}
              <button className="primary-theme-btn" onClick={() => { nextIndex();tableTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); }} disabled={isOnLastPage}>
                Next
              </button>
            </>
        )}
      </div>
    </div>
  ): null;
};

export default PaginationTable;
